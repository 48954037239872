<template>
    <layout-cabinet v-if="isReady">
        <template slot="content">
            <router-view />
        </template>
    </layout-cabinet>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LayoutCabinet from '@/views/layouts/LayoutCabinet';

export default {
    name: 'CabinetModule',
    components: {
        LayoutCabinet
    },
    computed: {
        ...mapState('cabinet', [
            'event'
        ]),
        eventId() {
            let a = 1;
            return this.$route.params.eventId;
        },
        isReady() {
            return !!this.event;
        }
    },
    watch: {
        eventId() {
            this._loadEvent();
        }
    },
    mounted() {
        if (this.eventId) {
            this._loadEvent();
        }
    },
    methods: {
        ...mapActions('cabinet', [
            'getEvent'
        ]),
        _loadEvent() {
            this.getEvent({ eventId: this.eventId });
        }
    }
};
</script>
