<template>
    <div
        v-if="authorized"
        class="user-profile-header"
    >
        <i
            class="fas fa-sign-out-alt fa-2x"
            @click="_logoutClick"
        />
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'UserProfileHeader',
    props: {
        onlineLogout: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        // TODO собрать в один модуль, либо сделать прокси геттеры
        ...mapState('auth', [
            'user',
            'isAuthorized'
        ]),

        ...mapGetters('lk/participant', [
            'participant',
            'authorized'
        ]),

        userInfo() {
            return this.participant ? (this.participant.name ? this.participant.name : '') : this.user;
        }
    },
    methods: {
        _logoutClick() {
            this.onlineLogout
                ? this.$onlineLkAccessManager.logoutUser() : this.$accessManager.logoutUser();
        }
    }
};
</script>

<style lang="scss">
@import "@vars";

.user-profile-header {
    line-height: 60px;
    color: #fff;
    display: flex;
    margin-right: 10px;
    cursor: pointer;
}
</style>
