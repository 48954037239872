<template>
    <div class="app-layout-cabinet">
        <el-container>
            <el-header>
                <cabinet-header
                    :online-mode="onlineMode"
                />
            </el-header>
            <el-row
                type="flex"
                class="row-bg"
                justify="center"
            >
                <el-col
                    :span="24"
                    :lg="{span:18}"
                >
                    <el-main>
                        <slot name="content" />
                    </el-main>
                </el-col>
            </el-row>
        </el-container>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import CabinetHeader from '@/modules/cabinet/components/CabinetHeader';

export default {
    name: 'LayoutCabinet',

    components: {
        CabinetHeader
    },

    props: {
        onlineMode: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        ...mapState('sidebar', [
            'isCollapsed'
        ]),

        width() {
            return this.isCollapsed ? '65px' : '300px';
        }
    }
};
</script>

<style lang="scss">
.app-layout-cabinet {
  height: 100%;

  .el-header {
    padding: 0;
  }

  & > .el-container {
    height: 100%;
  }
}
</style>
