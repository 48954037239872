<template>
    <div class="cabinet-header">
        <div class="cabinet-header__logo">
            <a href="http://blagostfest.org">
                <img
                    src="@/assets/img/logo.png"
                    alt="Фестиваль Благость"
                >
            </a>
        </div>
        <user-profile-header
            :online-logout="onlineMode"
        />
    </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { ROUTES } from '@/enums';
import UserProfileHeader from '@/modules/cabinet/components/UserProfileHeader';

export default {
    name: 'CabinetHeader',

    components: {
        UserProfileHeader
    },

    props: {
        onlineMode: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {};
    },

    computed: {
        ...mapState('sidebar', [
            'isCollapsed'
        ]),

        ...mapState('auth', [
            'user',
            'isAuthorized'
        ]),

        menu() {
            return [
                {
                    title: 'Фестиваль "Благость"',
                    route: ROUTES.HOME,
                    sub: []
                }
            ];
        }
    },

    methods: {
        ...mapMutations('sidebar', [
            'setIsCollapsed'
        ]),

        select(name) {
            if (name === 'collapse') {
                return this.toggleCollapse();
            }
            this.$router.push({ name });
        },

        toggleCollapse() {
            this.setIsCollapsed(!this.isCollapsed);
        }
    }
};
</script>

<style lang="scss">
.cabinet-header {
    margin: 0;
    background: #409eff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__logo {
        margin-left: 20px;
        display: flex;
    }

    .el-row {
        margin-top: 0 !important;
        margin-bottom: 0 !important;
    }

    .el-menu-demo {
        border: none !important;
    }
}
</style>
